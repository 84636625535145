class Middag {
    constructor() {

        this.doGlobalListeners();
        if (document.getElementById('weeks')) {
            //new MenuPlanner();
            new MenuPlannerCalendar();
        }
        if (document.getElementById('servings')) {
            new RecipeUnitScaling();
        }

        new Timer();

        this.generalScripts();

    }

    generalScripts(){
       if(document.querySelector('.recipe-header')) {
           let image = document.querySelector('.recipe-header').style.backgroundImage.slice(4, -1).replace(/"/g, "");
           this.getImageBrightness(image,this.hamburgerColor);
       }
        // BackgroundCheck.init({
        //     targets: '.hamburger-color',
        //     images: '.recipe-header',
        //     debug:true
        // });
    }

    hamburgerColor(brightness){
        if (brightness > 150){
            document.querySelector('.hamburger').classList.add('bg-light');
        }else{
            document.querySelector('.hamburger').classList.add('bg-dark');
        }
    }

    getImageBrightness(imageSrc,callback) {
        var img = document.createElement("img");
        img.src = imageSrc;
        img.style.display = "none";
        document.body.appendChild(img);

        var colorSum = 0;

        img.onload = function() {
            // create canvas
            var canvas = document.createElement("canvas");
            canvas.width = this.width;
            canvas.height = this.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(this,0,0);

            var imageData = ctx.getImageData(0,0,canvas.width,canvas.height);
            var data = imageData.data;
            var r,g,b,avg;

            for(var x = 0, len = data.length; x < len; x+=4) {
                r = data[x];
                g = data[x+1];
                b = data[x+2];

                avg = Math.floor((r+g+b)/3);
                colorSum += avg;
            }

            var brightness = Math.floor(colorSum / (this.width*this.height));
            callback(brightness);
        }
    }

    doGlobalListeners() {
        let that = this;


        document.querySelector('.to-top-button').addEventListener('click',function (e){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        });

        window.addEventListener('scroll',function (e){
            var top = (window.pageYOffset || document.documentElement.scrollTop)  - (document.documentElement.clientTop || 0);
            if(top > 500){
                document.querySelector('.to-top-button').classList.add('active');
            }else{
                document.querySelector('.to-top-button').classList.remove('active');
            }
        });
    if(document.querySelector('.member-menu-button')) {
        document.querySelector('.member-menu-button').addEventListener('click',function () {
            document.querySelector('.menu-members-menu-container').classList.toggle('active');
        })
    }
    }

    // General Ajax call
    ajaxCall(ajaxObject) {
        return new Promise(resolve => {
            console.log(2);
            jQuery.post(
                wp_ajax_data.ajax_url,

                ajaxObject,
                function (res) {
                    console.log(3);
                    resolve(res);
                }
            );
        });


    }
}


class Timer {
    regTime = /[0-9]+ ?-? ?[0-9]* ?(tim|min)? ?-? ?[0-9]* ?(tim|min)? ?-? ?[0-9]* ?(tim|min)? ?[0-9]* ?(tim|min)+/;
    colors = ['#F894C5', '#8c4ec4', '#5959d0', '#3aa5a5', '#327a32', '#6e6e6e', '#333333', '#a9482a'];

    constructor() {

        if (!localStorage.getItem('timers') && !document.querySelector('.hasTimer'))
            return;

        if (document.querySelector('.hasTimer')) {
            this.timerStringElements = document.querySelectorAll('.hasTimer');
        }
        this.timerEls = [];
        this.alarmSound = new Audio(wp_ajax_data.script_url + '/sounds/alarm.mp3');


        if (document.querySelector('.hasTimer')) {
            this.handleTimerStrings();
            this.listeners();
        }

        this.loadTimerLocalStorage();
        if (!this.timerContainer) {
            this.createTimerContainer();
        }

        this.timerTick();


    }


    /**
     * Main interval loop
     */
    timerTick() {
        let that = this;
        this.timerInterval = setInterval(function () {
            for (let i = 0; i < Object.keys(that.timers).length; i++) {
                if (that.timers[i].status === 'running') {
                    let now = new Date().getTime();
                    that.timers[i].currentTimeMS = that.timers[i].then - now;
                    that.outputTime(i);
                    if (that.timers[i].currentTimeMS <= 0) {
                        that.timers[i].status = 'alarm';
                    }
                    that.saveTimerLocalStorage();
                }

                if (that.timers[i].status === 'alarm') {

                    that.timers[i].clockEl.querySelector('.clock').innerHTML = "Tid ute, klicka för att ta bort"
                    that.alarmSound.currentTime = 0;

                    that.alarmSound.play();
                    that.timers[i].clockEl.querySelector('.clock').removeEventListener('click', that.pauseClock);
                    that.timers[i].clockEl.querySelector('.clock').addEventListener('click', function (e) {
                        that.timers[i].status = 'stopped';
                        that.alarmSound.pause();
                        that.resetTimer(i);
                        this.parentNode.remove();
                        that.saveTimerLocalStorage();
                    });
                    that.saveTimerLocalStorage();
                }
            }
            that.toggleTimerContainer();


        }, 1000);

    }


    loadTimerLocalStorage() {
    //TODO Fix that it breks when two recipes are up in seperaate windpws
        if (localStorage.getItem('timers')) {
            console.log(this.timers);
            this.timers = JSON.parse(localStorage.getItem('timers'));
            console.log(this.timers);
            if (!this.timerContainer) {
                this.createTimerContainer();
            }
            for (let i = 0; i < Object.keys(this.timers).length; i++) {
                if (this.timers[i].status === 'running' || this.timers[i].status === 'paused' || this.timers[i].status === 'alarm') {
                    this.createTimerElement(i);
                    this.timerListener(i);
                }
            }
            this.toggleTimerContainer();

        }

    }

    saveTimerLocalStorage() {
        let json = JSON.stringify(this.timers);
        localStorage.setItem('timers', json);
    }

    clearLocalStorage() {
        console.log("Clearing");
        localStorage.removeItem('timers');
    }

    /**
     * Writes time to element with key
     * @param key
     */
    outputTime(key) {
        let currentTime = this.timers[key].currentTimeMS;

        let hours = Math.floor((currentTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((currentTime % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((currentTime % (1000 * 60)) / 1000);
        let outputString = "";
        if (hours) {
            outputString += hours + " h ";
        }
        if (minutes) {
            outputString += minutes + " min ";
        }

        outputString += seconds + " sek ";
        this.timers[key].clockEl.querySelector('.clock').innerHTML = outputString;
    }

    /**
     * Extracts time from main string and adds span to string
     */
    handleTimerStrings() {
        let that = this;
        this.timers = {};
        this.timerStringElements.forEach(function (el, key) {

            that.timers[key] = {};
            that.timers[key].color = that.colors[key];
            that.timers[key].rname = el.getAttribute('data-rName');
            that.timers[key].rlink = el.getAttribute('data-rLink');


            let time = el.innerHTML.match(that.regTime)[0];
            el.innerHTML = el.innerHTML.replace(that.regTime, '<span id="timer-' + key + '" data-key="' + key + '" class="timer">' + time + '</span>');
            that.timerEls[key] = el.querySelector('#timer-' + key);
            that.timerEls[key].style.backgroundColor = that.timers[key].color;

            that.timers[key].time = that.convertTimeString(time);
            that.timers[key].originalTime = that.convertTimeString(time);

            that.timers[key].status = 'stopped';
        });

        this.convertAllTimesToMS();

    }

    /**
     * Handle all with strings and returns time in format 1.5 (1 hour 30 min)
     * @param timeString
     * @returns {number}
     */
    convertTimeString(timeString) {
        // 1 tim 30 min - 2 tim 30 min
        let tempArr = timeString.split(' ');
        let time = 0;
        // [1,tim,30,min,-,2,tim,30,min]
        for (let i = 0; i < tempArr.length; i++) {
            if (tempArr[i] === '-') {
                break;
            }
            if (tempArr[i] === 'tim' || tempArr[i] === 'min') {
                continue;
            }
            if (tempArr[i + 1] === 'tim') {
                time = time + parseInt(tempArr[i]);
            } else if (tempArr[i + 1] === 'min') {
                time = time + (parseInt(tempArr[i]) / 60);
            }
        }
        return time;
    }

    /**
     * Cleans the time string for easier handling
     * @param time
     * @returns {string}
     */
    sanitizeTimeString(time) {
        time = time.replace(' ', '');
        time = time.replace('minutes', '');
        time = time.replace('minute', '');
        time = time.replace('min', '');
        time = time.replace('hours', '');
        time = time.replace('hour', '');
        time = time.replace('h', '');

        return time;
    }

    /**
     * Creates the main container for the timers
     */
    createTimerContainer() {
        this.timerContainer = document.createElement('div');
        this.timerContainer.classList.add('timerContainer');

        let title = document.createElement('div');
        title.innerHTML = 'Timer för: ';

        let href = document.createElement('a');
        href.href = this.timers[0].rlink;
        href.innerHTML = this.timers[0].rname;

        title.appendChild(href);
        this.timerContainer.appendChild(title);

        document.body.append(this.timerContainer);
    }


    /**
     * Creates a single timer Element and wrapper and appends it to the container
     * @param key
     */
    createTimerElement(key) {


        this.timers[key].clockEl = document.createElement('div');
        this.timers[key].clockEl.setAttribute('data-key', key);

        let clockSpan = document.createElement('span');
        clockSpan.classList.add('clock');
        clockSpan.style.backgroundColor = this.timers[key].color;


        let stopButton = document.createElement('span');
        stopButton.classList.add('stop-button')


        this.timers[key].clockEl.appendChild(clockSpan);
        this.timers[key].clockEl.appendChild(stopButton);


        this.timerContainer.appendChild(this.timers[key].clockEl);
        this.outputTime(key);

    }

    /**
     * Does the necessary stuff to start the timer
     * @param key
     */
    startTimer(key) {
        let el = this.timerEls[key];
        if (this.timers[key].status === 'stopped') {

            this.timers[key].status = 'running';
            this.toggleTimerContainer();
            let now = new Date().getTime();
            this.timers[key].then = now + this.timers[key].currentTimeMS;
            this.createTimerElement(key);
            this.timerListener(key);


        }
    }

    /**
     * Helper function to return a hex color
     * @returns {string}
     */
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 10)];
        }
        return color;
    }

    /**
     * Returns if timer is running
     * @returns {boolean}
     */
    checkRunningTimers() {
        let running = false;
        for (let i = 0; i < Object.keys(this.timers).length; i++) {
            if (this.timers[i].status === 'running' || this.timers[i].status === 'paused' || this.timers[i].status === 'alarm') {
                running = true;
            }
        }

        if (!running) {

            this.clearLocalStorage();
        }
        return running;
    }


    toggleTimerContainer() {
        if (this.checkRunningTimers()) {
            if (!this.timerContainer.classList.contains('active')) {
                this.timerContainer.classList.add('active');
            }
        } else {
            if (this.timerContainer.classList.contains('active')) {
                this.timerContainer.classList.remove('active');
            }
        }
    }

    /**
     * Shows the container
     */
    showTimerContainer() {
        if (!this.timerContainer.classList.contains('active')) {
            this.timerContainer.classList.add('active');
        }
    }


    /**
     * Converts all times in the timer object to MS for easier use in startTimer
     */
    convertAllTimesToMS() {
        let that = this;

        for (let i = 0; i < Object.keys(this.timers).length; i++) {
            let time = that.timers[i].time;


            time = time * 60 * 60 * 1000;
            that.timers[i].currentTimeMS = time;
        }


    }

    /**
     * Reset single timer
     * @param key
     */
    resetTimer(key) {
        let time = 0;


        time = this.timers[key].originalTime;


        time = time * 60 * 60 * 1000;

        this.timers[key].currentTimeMS = time;
        this.timers[key].time = this.timers[key].originalTime;
    }

    /**
     * Timer Listeners, to be run after a timer is created
     * @param key
     */
    timerListener(key) {
        let that = this;
        this.timers[key].clockEl.querySelector('.clock').key = key;
        this.timers[key].clockEl.querySelector('.clock').that = this;
        this.timers[key].clockEl.querySelector('.clock').addEventListener('click', this.pauseClock);
        this.timers[key].clockEl.querySelector('.stop-button').addEventListener('click', function (e) {
            that.timers[key].status = 'stopped';
            that.resetTimer(key);
            that.timers[key].clockEl.remove();
        });


    }


    createAndShowModal(key) {
        let overlay = document.createElement('div');
        overlay.classList.add('timer-overlay');
        overlay.addEventListener('click', function (e) {
            if (e.target === this) {
                this.remove();
            }
        });

        let modal = document.createElement('div');
        modal.classList.add('timer-modal');

        let time = document.createElement('input');
        time.value = this.timers[key].time * 60;
        time.type = 'number';
        time.autocomplete = 'off';
        time.id = "clockTimeModal";

        let minus = document.createElement('span');
        minus.classList.add('minus');

        minus.addEventListener('click', function (e) {
            time.value--;
        });

        let plus = document.createElement('span');
        plus.classList.add('plus');

        plus.addEventListener('click', function (e) {
            time.value++;
        });

        let start = document.createElement('div');
        start.classList.add('start');
        start.innerHTML = 'Starta';

        let close = document.createElement('div');
        close.classList.add('close');


        close.addEventListener('click', function (e) {
            close.parentNode.parentNode.remove();
        });


        let label = document.createElement('label');
        label.innerHTML = "Minuter";
        label.setAttribute('for', 'clockTimeModal');

        modal.appendChild(close);
        modal.appendChild(minus);
        modal.appendChild(time);
        modal.appendChild(plus);
        modal.appendChild(label);
        modal.appendChild(start);
        overlay.appendChild(modal);
        document.body.appendChild(overlay);
        start.inputEL = time;
        return start;
    }

    /**
     * Pauses the timer
     */
    pauseClock() {
        let key = this.key;
        let that = this.that;
        if (that.timers[key].status === 'running') {
            let pausedEl = document.createElement('span');
            pausedEl.innerHTML = 'Pausad';
            pausedEl.classList.add('paused');
            that.timers[key].status = 'paused';
            this.prepend(pausedEl);
        } else {
            let now = new Date().getTime();
            that.timers[key].then = now + that.timers[key].currentTimeMS;
            this.querySelector('.paused').remove();
            that.timers[key].status = 'running';
        }
    }

    /**
     * Main listeners
     */
    listeners() {
        let that = this;
        this.timerEls.forEach(function (el) {
            el.addEventListener('click', function (e) {

                let modal = that.createAndShowModal(el.getAttribute('data-key'));
                modal.addEventListener('click', function (e) {

                    that.timers[el.getAttribute('data-key')].currentTimeMS = (this.inputEL.value * 60 * 1000);

                    that.startTimer(el.getAttribute('data-key'));
                    this.parentNode.parentNode.remove();
                });

                that.alarmSound.play();
                that.alarmSound.pause();
            });
        });
    }
}


class MenuPlannerCalendar {
    constructor() {
        this.chosenCategories = [];

        this.events = [];
        this.dayMenuEl = document.getElementById('dayMenu');

        this.getEvents();



    }

    loadCalendar(){
        let that = this;
        let calendarEl = document.getElementById('calendar');
        this.calendar = new FullCalendar.Calendar(calendarEl, {
            initialView: 'dayGridMonth',
            editable:true,
            droppable: true,
            selectable:true,
            weekNumberCalculation: 'ISO',
            displayEventTime: false,
            eventBackgroundColor: '#F894C5',
            eventBorderColor:'transparent',
            eventDisplay:'block',
            fixedWeekCount:false,
            events:this.events,
            customButtons: {
                myCustomButton: {
                    text: 'Slumpa recept för månaden',
                    click: function() {
                        alert('Kommer snart!');
                    }
                }
            },
            headerToolbar: {
                left: 'prev,next today myCustomButton',
                center: 'title',
                right: 'dayGridMonth'
            },
            dateClick:function (info){
                that.showSidebar();
                that.getEventsForDay(info);

            },
            eventDrop: function (info){
                console.log(info);
                let allEvents = that.calendar.getEvents();
                let eventsForDay = allEvents.filter(function (val,num){
                    if (val.startStr.includes(info.event.startStr.split('T')[0])){
                        return val;
                    }

                });
                console.log(eventsForDay);
                if (eventsForDay.length > 3){
                    info.revert();
                    return false;
                }else{
                    let eventObj = {
                        'recipe': info.event.extendedProps.rid,
                        'dateStr':info.event.startStr.split('T')[0],
                        'row':info.event.extendedProps.row
                    };
                    that.updateSingleEvent(eventObj);

                    return true;
                }


            },
            eventReceive: function (info){
                console.log("Hello");
                let allEvents = that.calendar.getEvents();
                let eventsForDay = allEvents.filter(function (val,num){
                    if (val.startStr.includes(info.event.startStr)){
                        return val;
                    }

                });
                if (eventsForDay.length > 3){
                    info.revert();
                    return false;
                }else{
                    info.rid = info.draggedEl.getAttribute('data-rid');
                    let eventObj = {
                        'recipe': info.rid,
                        'dateStr':info.event.startStr
                    };
                    that.saveSingleEvent(eventObj);


                    return true;
                }



            }
        });
        this.calendar.render();
        this.pageLoadListeners();
    }
    showSidebar(){

        document.querySelector('.week-wrapper').classList.add('expanded');
        this.calendar.render();
    }


    removeSingleEvent(row,info){
        let that = this;
        jQuery.post(
            wp_ajax_data.ajax_url,
            {
                'action': 'mp_remove_single_event',
                'row':row
            },
            function (res) {
                console.log(res);
                that.getEvents();
                that.getEventsForDay(info);
                that.calendar.render();
            }
        )
    }
    updateSingleEvent(eventObj){
        jQuery.post(
            wp_ajax_data.ajax_url,
            {
                'action': 'mp_update_single_event',
                'info':eventObj
            },
            function (res) {
                console.log(res);
            }
        )
    }

    saveSingleEvent(eventObj){


        jQuery.post(
            wp_ajax_data.ajax_url,
            {
                'action': 'mp_save_single_event',
                'info':eventObj
            },
            function (res) {
                return res.success;
            }
        )
    }

    getEventsForDay(info){
        let that = this;
        jQuery.post(
            wp_ajax_data.ajax_url,
            {
                'action': 'mp_get_events_for_day',
                'day':info.dateStr
            },
            function (res) {

                document.querySelector('.day-title').innerHTML = new Intl.DateTimeFormat('sv-SE', {
                    day: "numeric",
                    month: 'long'
                }).format(info.date);



                that.dayMenuEl.querySelectorAll('ul li').forEach(function (el,key){



                    let recipe = false;

                    res.data.forEach(function (o){
                        if (o.start.includes(el.getAttribute('data-time'))){
                            recipe = o;
                        }
                    });




                    if(recipe){
                        el.querySelector('a').remove();
                        let a = document.createElement('a');


                        a.href = recipe.permalink;

                        a.target = '_blank';



                        let remove = document.createElement('div');
                        remove.classList.add('removeRecipeFromDay');
                        remove.addEventListener('click',function (){
                            res.data.forEach(function (item){
                                if (item.start.includes(el.getAttribute('data-time'))){
                                    that.removeSingleEvent(item.row,info);
                                }
                            })
                        });
                        el.appendChild(remove);


                        let random = document.createElement('div');
                        random.classList.add('randomRecipeDay');
                        random.addEventListener('click',function (){
                            res.data.forEach(function (item){
                                if (item.start.includes(el.getAttribute('data-time'))){
                                    that.randomRecipeReplace(item.row,info);
                                }
                            })

                        });
                        el.appendChild(random);

                        let p = document.createElement('p');
                        p.innerHTML = recipe.title;
                        let img = document.createElement('img');
                        img.src = recipe.image;
                        a.innerHTML = "";
                        a.appendChild(img);
                        a.appendChild(p);
                        el.querySelector('.recipe-info').appendChild(a);

                    }else{
                        el.querySelector('a').remove();
                        let a = document.createElement('a');
                        a.innerHTML = "";
                        a.target = "";
                        a.href = "#";
                        a.addEventListener('click',function (e){
                            e.preventDefault();
                            that.randomRecipe(info.dateStr,el.getAttribute('data-time'),info);

                        })
                        let div = document.createElement('div');
                        div.classList.add('placeholder');

                        let p = document.createElement('p');
                        p.innerHTML = "Slumpa recept";
                        a.appendChild(div);
                        a.appendChild(p);
                        el.querySelector('.recipe-info').appendChild(a);
                    }
                    that.calendar.render();


                });







            }
        )
    }

    randomRecipe(date,time,info){
        let that = this;
        jQuery.post(
            wp_ajax_data.ajax_url,
            {
                'action': 'mp_get_random_recipe',
                'date': date,
                'time': time
            },
            function (res) {
                that.getEvents();
                that.getEventsForDay(info);
                that.calendar.render();
            }
        )
    }
    randomRecipeReplace(row,info){
        let that = this;
        jQuery.post(
            wp_ajax_data.ajax_url,
            {
                'action': 'mp_get_random_replace_recipe',
                'row': row
            },
            function (res) {
                that.getEvents();
                that.getEventsForDay(info);
                that.calendar.render();
            }
        )
    }

    getEvents(){
        let that = this;
        jQuery.post(
            wp_ajax_data.ajax_url,
            {
                'action': 'mp_get_events',
            },
            function (res) {

                that.events = res.data;
                that.loadCalendar();
            }
        )
    }

    searchMenuRecipes(q, categories) {
        let that = this;
        console.log("Sending request", [q, categories]);
        this.requestSending = true;
        jQuery.post(
            wp_ajax_data.ajax_url,
            {
                'action': 'mp_search_recipes',
                'query': q,
                'categories': categories
            },
            function (res) {
                document.getElementById('searchResults').innerHTML = res;
                // document.querySelectorAll('#searchResults .results ul li').forEach(element => {
                //     element.addEventListener('dragstart', function (e) {
                //         e.dataTransfer.setData("text", e.target.getAttribute('drag-id'));
                //     });
                //
                // });

                new FullCalendar.Draggable(document.querySelector('#searchResults .results ul'),{
                    itemSelector:'li',
                    eventData: function(eventEl) {
                        return {
                            title: eventEl.getAttribute('data-name')
                        };
                    }
                });
                that.calendar.render();

                that.requestSending = false;


            }
        )
    }

    pageLoadListeners() {
        let that = this;




        /*
                    SEARCH LISTENERS
         */
        document.getElementById('query').addEventListener('keyup', function (e) {
            var time = 0;

            var interval = setInterval(function () {
                time = time + 100;
                if (time > 500) {
                    if (!that.requestSending) {
                        that.searchMenuRecipes(document.getElementById('query').value, that.chosenCategories);
                        clearInterval(interval);
                    } else {
                        clearInterval(interval);
                    }

                }
            }, 100);

        });

        document.getElementById('searchMenu').addEventListener('submit', function (e) {
            e.preventDefault();

            that.searchMenuRecipes(document.getElementById('query').value, that.chosenCategories);

        });

        document.querySelectorAll('.categories ul li').forEach(element => {
            element.addEventListener('click', function (e) {
                var term_id = element.getAttribute('data-termId');

                if (that.chosenCategories.includes(term_id)) {
                    var indexOf = that.chosenCategories.indexOf(term_id);
                    console.log(indexOf);
                    that.chosenCategories.splice(indexOf, 1);
                    element.classList.remove('active');
                } else {
                    that.chosenCategories.push(term_id);
                    element.classList.add('active');
                }

                that.searchMenuRecipes(document.getElementById('query').value, that.chosenCategories);


            });
        });
    }
}

class MenuPlanner {


    constructor() {
        this.loadingSpinner = document.createElement('i');
        this.requestSending = false;
        this.chosenCategories = [];
        this.loadingSpinner.classList.add('fas', 'fa-circle-notch', 'fa-spin', 'loading');
        this.pageLoadListeners();

        if (window.location.hash.includes('week')) {
            let week = window.location.hash;
            week = week.substring(6, week.length);
            this.loadSingleWeek(week);
        } else {
            this.loadWeeks();
        }


    }

    async loadSingleWeek(week) {


        let ajaxObject = {
            'action': 'mp_show_single_week',
            'week': week
        }
        if (document.querySelector('#weeks')) {
            console.log("Checking flow");
            console.log(1);
            let res = await this.ajaxCall(ajaxObject);
            console.log(4);
            document.querySelector('#weeks').innerHTML = res;
            //this.listeners();
            //document.querySelector('#addWeekToPlanner').innerText = "Lägg till vecka";
        }
    }

    //Load Weeks
    async loadWeeks() {
        let ajaxObject = {
            'action': 'mp_show_weeks'
        }
        if (document.querySelector('#weeks')) {
            console.log("Checking flow");
            console.log(1);
            let res = await this.ajaxCall(ajaxObject);
            console.log(4);
            document.querySelector('#weeks').innerHTML = res;
            this.listeners();
            document.querySelector('#addWeekToPlanner').innerText = "Lägg till vecka";
        }
    }


    //addWeek
    async addWeek() {
        let ajaxObject = {
            'action': 'mp_add_week'
        };
        await this.ajaxCall(ajaxObject);
        this.loadWeeks();
    }


    //Remove week
    async removeWeek(index) {
        let ajaxObject = {
            'action': 'mp_remove_week',
            'removeRow': index
        }
        await this.ajaxCall(ajaxObject);
        this.loadWeeks();
    }


    //TODO Review this one
    moveDay(e) {
        let that = this;
        var id = e.dataTransfer.getData("text");
        console.log(id);
        var srcEl = document.getElementById(id);
        var targetEl = e.target.closest('.recipe-info');

        var moveTarget = (srcEl.getAttribute('data-recieve-drag') !== "false") ? true : false;

        if (moveTarget) {
            srcEl.classList.add('changing');
            srcEl.appendChild(this.loadingSpinner);
        }


        targetEl.classList.add('changing');

        targetEl.appendChild(this.loadingSpinner);


        jQuery.post(
            wp_ajax_data.ajax_url,
            {
                'action': 'mp_move_day',
                'srcRows': srcEl.getAttribute('data-rows'),
                'targetRows': targetEl.getAttribute('data-rows'),
                'moveTarget': moveTarget
            },
            function (res) {
                console.log(res);

                that.loadWeeks();
            }
        )
    }

    async randomRecipe(index) {
        let ajaxObject = {
            'action': 'mp_random_recipe',
            'randomPlacement': index
        };
        await this.ajaxCall(ajaxObject);
        this.loadWeeks();
    }

    async removeRecipe(index) {
        let ajaxObject = {
            'action': 'mp_remove_recipe',
            'removeRow': index
        };
        await this.ajaxCall(ajaxObject);
        this.loadWeeks();
    }

    //TODO Review this one
    searchMenuRecipes(q, categories) {
        let that = this;
        console.log("Sending request", [q, categories]);
        this.requestSending = true;
        jQuery.post(
            wp_ajax_data.ajax_url,
            {
                'action': 'mp_search_recipes',
                'query': q,
                'categories': categories
            },
            function (res) {
                document.getElementById('searchResults').innerHTML = res;
                document.querySelectorAll('#searchResults .results ul li').forEach(element => {
                    element.addEventListener('dragstart', function (e) {
                        e.dataTransfer.setData("text", e.target.getAttribute('drag-id'));
                    });

                });
                that.requestSending = false;


            }
        )
    }


    // General Ajax call
    ajaxCall(ajaxObject) {
        return new Promise(resolve => {
            console.log(2);
            jQuery.post(
                wp_ajax_data.ajax_url,
                ajaxObject,
                function (res) {
                    console.log(3);
                    resolve(res);
                }
            );
        });


    }

    panScroll(e) {
        e = e || window.event;
        var dragX = e.screenX, dragY = e.screenY;
        var height = window.innerHeight;
        if (dragY < 200) {
            window.scrollTo({
                top: window.scrollY - 60,
                left: 0,
                behavior: 'smooth'

            });

        }
        if (dragY > (height - 100)) {
            window.scrollTo({
                top: window.scrollY + 60,
                left: 0,
                behavior: 'smooth'

            });
        }

        console.log(height);
    }

    listeners() {
        let that = this;

        document.querySelector('#addWeekToPlanner').addEventListener("click", function () {
            that.addWeek();
        });

        document.querySelectorAll('.removeRow').forEach(element => {
            element.addEventListener('click', function () {
                element.parentElement.parentElement.classList.add('changing');
                that.removeWeek(element.getAttribute('data-row-index'));
            });
        });

        document.querySelectorAll('.removeRecipe').forEach(element => {
            element.addEventListener('click', function () {
                element.parentElement.parentElement.parentElement.classList.add('changing');
                element.parentElement.parentElement.parentElement.parentElement.appendChild(that.loadingSpinner);
                that.removeRecipe(element.getAttribute('data-row-index'));
            });
        });

        document.querySelectorAll('.randomRecipe').forEach(element => {
            element.addEventListener('click', function () {
                element.parentElement.parentElement.parentElement.classList.add('changing');
                element.parentElement.parentElement.parentElement.parentElement.appendChild(that.loadingSpinner);
                that.randomRecipe(element.getAttribute('data-row-index'));
            });
        });

        document.querySelectorAll('.week').forEach(element => {
            element.addEventListener('click', function (e) {
                window.location.hash = "week:" + element.getAttribute('data-week');
                that.loadSingleWeek(element.getAttribute('data-week'));
            });
        });

        //Initiate drag, set needed data
        document.querySelectorAll('.week ul > li .recipe-info').forEach(element => {

            element.addEventListener('dragstart', function (e) {
                e.dataTransfer.setData("text", e.target.getAttribute('drag-id'));
            });
        });

        // Dragover and drop event
        document.querySelectorAll('.week > ul').forEach(element => {
            element.addEventListener('dragover', function (e) {
                e.preventDefault();
            });
            element.addEventListener('drop', function (e) {
                e.preventDefault();
                console.log(e);
                that.moveDay(e);

            });
        });

        document.querySelectorAll('.expandRecipeControls').forEach(element => {
            element.addEventListener('click', function (e) {
                e.preventDefault();
                document.getElementById(element.getAttribute('target')).classList.toggle('active');
                document.getElementById(element.getAttribute('target')).focus();
            });
        });

        document.querySelectorAll('.expandable-controls').forEach(element => {
            element.addEventListener('blur', function (e) {
                e.target.classList.toggle('active');
            });
        });

        document.querySelectorAll('.searchRecipes').forEach(element => {
            element.addEventListener('click', function (e) {
                document.getElementById('query').focus();
                document.getElementById('query').scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            });
        });

        document.addEventListener('dragover', function (e) {
            that.panScroll(e);
        }, false);


    }

    pageLoadListeners() {
        let that = this;
        document.getElementById('query').addEventListener('keyup', function (e) {
            var time = 0;

            var interval = setInterval(function () {
                time = time + 100;
                if (time > 500) {
                    if (!that.requestSending) {
                        that.searchMenuRecipes(document.getElementById('query').value, that.chosenCategories);
                        clearInterval(interval);
                    } else {
                        clearInterval(interval);
                    }

                }
            }, 100);

        });

        document.getElementById('searchMenu').addEventListener('submit', function (e) {
            e.preventDefault();

            that.searchMenuRecipes(document.getElementById('query').value, that.chosenCategories);

        });

        document.querySelectorAll('.categories ul li').forEach(element => {
            element.addEventListener('click', function (e) {
                var term_id = element.getAttribute('data-termId');

                if (that.chosenCategories.includes(term_id)) {
                    var indexOf = that.chosenCategories.indexOf(term_id);
                    console.log(indexOf);
                    that.chosenCategories.splice(indexOf, 1);
                    element.classList.remove('active');
                } else {
                    that.chosenCategories.push(term_id);
                    element.classList.add('active');
                }

                that.searchMenuRecipes(document.getElementById('query').value, that.chosenCategories);


            });
        });
    }


}


class RecipeUnitScaling {
    constructor() {
        this.previousServings = document.getElementById("servings").value;
        this.wholeNumber = 0;
        this.numerator = 0;
        this.denominator = 0;
        this.unit = null;
        this.quantity = null;
        this.currentElem = null;
        this.originalData = {};
        this.scaleRecipe(document.getElementById("servings").value);
        this.listeners();
    }


    scaleRecipe(newServings) {
        let that = this;
        let ingredients = document.querySelectorAll('.ingrediens-wrapper > ul > li > span.ingredient');
        let difference = (newServings / this.previousServings);


        ingredients.forEach((item, i) => {
            that.quantity = item.getAttribute('data-quantity');
            if(that.quantity.includes("-")){
                let tmp = that.quantity.split("-");
                let sum = tmp.reduce(function (p,c,i){
                    return parseInt(p)+parseInt(c);
                });
                sum = sum / tmp.length;
                that.quantity = sum+'';
            }


            that.quantity = math.evaluate(that.quantity.replace(" ", "+"));
            that.quantity = Math.round(difference * that.quantity * 10) / 10;
            that.unit = item.getAttribute('data-unit');
            this.currentElem = item;


            if (!isNaN(this.quantity)) {
                this.convertUnit();
                this.quantity = this.round(this.quantity, 0.25);


                this.fractionCalculations();




                this.outputResult();
            }


        });


    }

    outputResult() {
        let outputString = "";
        if (this.unit === "g" || this.unit === "ml"){
            if (!isNaN(this.denominator) && !isNaN(this.numerator)){
                this.wholeNumber++;
                this.denominator = NaN;
                this.numerator = NaN;
            }
        }

        if (isNaN(this.wholeNumber)) {
            this.wholeNumber = null;
        } else {
            outputString += this.wholeNumber + " ";
        }
        if (!isNaN(this.numerator) && !isNaN(this.denominator)) {
            outputString += this.numerator + "/" + this.denominator + " ";
        }



        //this.currentElem.setAttribute('data-quantity', this.quantity);
        //this.currentElem.setAttribute('data-unit', this.unit);
        this.currentElem.innerHTML = outputString + this.unit;
    }

    fractionCalculations() {
        let quantityFraction = this.prettyFractions(this.quantity);
        var match = quantityFraction.match(/^(?:(\d+)|(?:(\d+)(?: |&nbsp;))?(?:(\d+)\/(\d+))?)$/); // 1 1/2  1/2
        this.wholeNumber = +(match[1] || match[2]);
        this.numerator = +match[3];
        this.denominator = +match[4];
        this.simplifyFraction();

        this.reduceFraction();
    }

    round(value, step) {
        step || (step = 1.0);
        var inv = 1.0 / step;
        return Math.round(value * inv) / inv;
    }

    convertUnit() {
        if (this.conversionTable.noConvert) {
            return;
        }
        if (this.unit == "" || this.unit == "stänk av" || this.unit == "nypa" || this.unit == "knippe") {

            return;
        }


        let min = 0;
        if (this.conversionTable[this.unit].min) {
            min = this.conversionTable[this.unit].min.value
        }

        let max = Infinity;

        if (this.conversionTable[this.unit].max) {
            max = this.conversionTable[this.unit].max.value;
        }


        if (this.quantity < min) {
            this.quantity = this.conversionTable[this.unit].to[this.conversionTable[this.unit].min.next] * this.quantity;
            this.unit = this.conversionTable[this.unit].min.next;
        }


        if (this.quantity > max) {
            this.quantity = this.quantity / this.conversionTable[this.conversionTable[this.unit].max.next].to[this.unit];

            this.unit = this.conversionTable[this.unit].max.next;

        }


    }


    prettyFractions(fraction) {
        var gcd = function (a, b) {
            if (b < 0.0000001) return a;                // Since there is a limited precision we need to limit the value.

            return gcd(b, Math.floor(a % b));           // Discard any fractions due to limitations in precision.
        };

        var len = fraction.toString().length - 2;

        var denominator = Math.pow(10, len);
        var numerator = fraction * denominator;

        var divisor = gcd(numerator, denominator);    // Should be 5

        numerator /= divisor;                         // Should be 687
        denominator /= divisor;                       // Should be 2000

        return Math.floor(numerator) + '/' + Math.floor(denominator);
    }

    isImproperFraction() {
        return this.numerator >= this.denominator;
    }

    isFraction() {
        return !!(this.numerator && this.denominator);
    }

    gcd(a, b) {
        return b ? this.gcd(b, a % b) : a;
    };

    roundNumerator() {
        this.numerator = Math.round(this.numerator / 5) * 5;
    }


    reduceFraction() {
        if (this.isFraction()) {
            var gcd = this.gcd(this.numerator, this.denominator);
            this.numerator /= gcd;
            this.denominator /= gcd;
        }
    }

    simplifyFraction() {
        if (this.isImproperFraction()) {
            this.wholeNumber |= 0;
            this.wholeNumber += Math.floor(this.numerator / this.denominator);
            var modulus = this.numerator % this.denominator;
            if (modulus) {
                this.numerator = modulus;
            } else {
                this.numerator = this.denominator = NaN;
            }
        } else if (this.numerator == 0) {
            this.wholeNumber |= 0;
            this.numerator = this.denominator = NaN;
        }
    }

    listeners() {
        let that = this;


        document.getElementById("servings").addEventListener('change', function (e) {

            if (this.value < 1) {
                e.target.value = 1;
                return false;
            }
            that.scaleRecipe(this.value);

        });
    }

    conversionTable = {
        l: {
            to: {
                dl: 10
            },
            max: {
                value: 10
            },
            min: {
                value: 1 / 2,
                next: 'dl'
            }
        },
        dl: {
            to: {
                msk: 6.66,
                l: 0.1,
                ml:100
            },
            min: {
                value: 4 / 5,
                next: 'msk'
            },
            max: {
                value: 10,
                next: 'l'
            }
        },
        msk: {
            to: {
                tsk: 3,
                dl: 0.15
            },
            min: {
                value: 1 / 2,
                next: 'tsk'
            },
            max: {
                value: 5,
                next: 'dl'
            }
        },
        tsk: {
            to: {
                msk: 1 / 3,
                krm: 5
            },
            max: {
                value: 2,
                next: 'msk'
            },
            min: {
                value: 1 / 2,
                next: 'krm'
            }
        },
        krm: {
            max: {
                value: 1.50,
                next: 'tsk'
            },
            to: {
                tsk: 1 / 5
            }
        },
        knippe: {
            noConvert: true
        },
        st: {
            noConvert: true
        },
        ml: {
            to: {
                dl: 1
            },
            max: {
                value: 100,
                next: 'dl'
            },
            min: {
                value: 1
            }
        },
        kg: {
            to: {
                g: 1000
            },
            min: {
                value: 1000,
                next: 'g'
            }
        },
        g: {
            to: {
                kg: 1
            },
            max: {
                value: 1000,
                next: 'kg'
            },
            min: {
                value: 1
            }
        }
    };
}

class Listeners {
    constructor() {

    }
}


jQuery(document).ready(function ($) {
    new Middag();
});
